<template id="app">
<v-ons-page id="main" >
    <v-ons-tabbar id="maintabbar" position="top"  :index.sync="tabbarIndex" :tabs="tabs" @postchange="resetnewpostcount()" ></v-ons-tabbar>
  </v-ons-page>
</template>
<script>
import olmap2 from "../components/olmap2.vue";
import bca from "../components/bca.vue";
import ownerinfo from "../components/ownerinfo";
//import propertyinfo from "../components/propertyinfo.vue";
import search from "../components/Search.vue";
import Router from 'vue-router';
import posts2 from "../components/posts2.vue";
// Import the EventBus we just created.
import EventBus from '../config/event-bus';



export default {
  name: "app",
  Router,
  data: function() {
    return {
      tabbarIndex:0,
      newpostcount:'',
      tabs: [
        { label: "Map", page: olmap2, icon: "md-map" },
       
        { label: "Details", page: ownerinfo, icon: "md-assignment" },
        //{ label: "Property", page: propertyinfo, icon: "md-city" },
       
        { label: "BCA", page: bca, icon: "md-book" } ,
        { id: "postTab", label: "Posts", page: posts2,icon: "fa-comment-alt"}  ,
        { label: "Search", page: search, icon: "md-search" }  


      ],
      gPID:null 
    };
  },
  mounted(){
    EventBus.$on("newpostcount" ,(data)=>{
          //console.log("newpostcount:",data)
          
         // console.log("activ tab",document.getElementById("maintabbar").getActiveTabIndex())
          if (document.getElementById("maintabbar").getActiveTabIndex() != 4)
          {
            this.newpostcount=data
            this.updatenewpostcount()
          }
          else 
          {
            this.newpostcount=''
            this.resetnewpostcount()
          }
                  

      })
  },
  methods:{
    resetnewpostcount(){
      var postTab=document.getElementById("postTab")
       postTab.setAttribute("badge",'')
    },
    updatenewpostcount(){
            var postTab=document.getElementById("postTab")
       postTab.setAttribute("badge",'new')
    }
  }
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
html,
body {
  
  margin:0;
}



</style>