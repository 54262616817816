import Vue from 'vue'
import Router from 'vue-router'
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import MainApp from '../views/MainApp.vue'
import Ownerinfo from '../components/ownerinfo.vue'
import Bookmark from '../components/bookmark.vue'
import Search from '../components/Search.vue'
import RealnetReport from '../components/realnetReport'
import Streetview from '../components/streetview.vue'
import Posteditor from '../components/posteditor.vue'
import Editowner from '../components/editOwner2.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'root',
            component: MainApp,
            meta: {
                protected: true
            }
        },
        {
            path: '/ownerinfo',
            name: 'ownerinfo',
            component: Ownerinfo,
            meta: {
                protected: true
            }
        },
        {
            path: '/bookmark',
            name: 'bookmark',
            component: Bookmark,
            meta: {
                protected: true
            }
        },
        {
            path: '/mainapp',
            name: 'mainapp',
            component: MainApp,
            meta: {
                protected: true
            }
        },
        {
            path: '/transaction',
            name: 'transaction',
            component: RealnetReport
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout
        },
        {
            path: '/search',
            name: 'search',
            component: Search,
            props:true
        },
        {
            path: '/streetview',
            name: 'streetview',
            component:Streetview,
            props:true
        },        {
            path: '/posteditor',
            name: 'posteditor',
            component:Posteditor,
            props:true
        },
        {
            path: '/editowner',
            name: 'editowner',
            component:Editowner,
            props:true,
            meta: {
                protected: true
            }
        }

    ]
})

