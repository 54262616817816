<template>
  <v-ons-page>
    <v-ons-toolbar>
      <div class="left">
        <v-ons-toolbar-button @click="GoBack">Back
      </v-ons-toolbar-button>
      </div>
      <div class="center">StreetView</div>
    </v-ons-toolbar>
    <div>
      <div id="pano" style="margin-top:3px;margin-left:3px;width: 98vw; height: 95vh;"></div>
    </div>
  </v-ons-page>
</template>

<script>

import router from "../router"
import state from  '../state'
export default {
  data() {
  return {
    panorama: null,
    lat:null,
    lon:null,
    state
  }
  },
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDEtklVqe60jt7Z6NpX6DrBbu-f2UKePGs`,
          async: true,
          defer: true,
          callback: () => this.MapsInit(), // will declare it in methods
        },
      ],
    };
  },

  methods: {
    GoBack() {
      this.panorama=null
      document.getElementById("pano").innerHTML=""
      router.go(-1)
      
    },
    MapsInit() {
      console.log("MapInit called");
      var sv = new window.google.maps.StreetViewService();
      this.panorama = new window.google.maps.StreetViewPanorama(
        document.getElementById("pano"),
        {
          addressControl:false,
          linksControl:false,
          fullscreenControl:false,
        }
      );
      
      console.log("sv state lonlat",state.lonlat);
      sv.getPanorama(
        // 49.2630174,-122.883528 home
        // increase radius to 150 to get streetview in high chances for big size parcels
        { location: { lat: this.state.lonlat[1], lng:this.state.lonlat[0]}, radius: 150, source:'outdoor' },
        this.processSVData
      );
    },
    processSVData(data, status) {
      if (status === window.google.maps.StreetViewStatus.OK) {
        console.log("ok");
        console.log("POV",data.location.pano)
        this.panorama.setPano(data.location.pano);
        this.panorama.setPov({
         heading: 60,
         pitch: 15,
        });
        console.log("POV",this.panorama)
        this.panorama.setVisible(true);
      } else {
        console.error("Street View data not found for this location.");
      }
    },
  },
}
</script>