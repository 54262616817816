import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import XYZ from "ol/source/XYZ";
import TileWMS from "ol/source/TileWMS";
import KML from "ol/format/KML";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import { Fill, Stroke, Style, Text } from "ol/style";
import fb from "../firebase";
import { store } from "../store";
import MapboxVectorLayer from "ol/layer/MapboxVector";
import MVT from 'ol/format/MVT';
import VectorTileLayer from 'ol/layer/VectorTile';
import VectorTileSource from 'ol/source/VectorTile';
import {createXYZ} from 'ol/tilegrid';

const key =
  "pk.eyJ1IjoiYWxmcmVkLWN3LWNoZW4iLCJhIjoiY2w0azZ3MG1hMTk5djNkb2JxZzhscnVtaCJ9.LzDKJGXqD4lOlSERZPOqsw";
var style = new Style({
  fill: new Fill({
    color: "rgba(255, 255, 255, 0.6)",
  }),
  stroke: new Stroke({
    color: "#319FD3",
    width: 1,
  }),
  text: new Text({
    font: "12px Calibri,sans-serif",
    fill: new Fill({
      color: "#000",
    }),
    stroke: new Stroke({
      color: "#fff",
      width: 3,
    }),
  }),
});

var indmap = function() {
  var indlayer = new VectorLayer({
    source: new VectorSource({
      url: "data/GV_Industrial.kml",
      format: new KML(),
    }),
  });
  indlayer.getSource().on("change", function(evt) {
    const source = evt.target;
    if (source.getState() === "ready") {
      const numFeatures = source.getFeatures().length;
      console.log("Count after change: " + numFeatures);
    }
  });
  return indlayer;
};
var mbmap = function() {
  var mblayer = null;
  mblayer = new MapboxVectorLayer({
    styleUrl: "mapbox://styles/mapbox/light-v10",
    accessToken: key,
    type: "base",
    baseLayer: true,
    zIndex: 0,
  });
  return mblayer;
};

var streetmap = function() {
  var mapbaselayer = null;
  mapbaselayer = new TileLayer({
    //source: new OSM(),

    source: new XYZ({
      //url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      url:"https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}@2x.png", //stadia maps
      tilePixelRatio: 2,
      maxZoom:20
    }),
    type: "base",
    baseLayer: true,
  });
  return mapbaselayer;
};
var googlemap = function() {
  var mapbaselayer = null;
  mapbaselayer = new TileLayer({
    source: new XYZ({
      attributions:
        '© <a href="https://cloud.google.com/maps-platform/terms/" target="_blank">Powered By Google. </a>',
      //attributionsCollapsible: false,
      url: "https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
      maxZoom: 18,
    }),
    type: "base",
    baseLayer: true,
  });
  return mapbaselayer;
};
var satellitemap = function() {
  var additonallayers = null;
  additonallayers = new TileLayer({
    title: "Satellite view",
    name: "GSatellite",
    zIndex: 3,
    visible: false,
    source: new XYZ({
      attributions: [
        "Powered by Esri",
        "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
      ],
      url:
        "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
      maxZoom: 23,
    }),
  });
  return additonallayers;
};
/*
var bcparcelmap = function() {
  var parcelLayer = new TileLayer({
    title: "Parcel lines",
    displayInLayerPopup: "0",
    opacity: 0.9,
    minZoom: 12,
    zIndex: 5,

    source: new TileWMS({
      url:
        "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
      crossOrigin: null,
      params: {
        LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
        STYLES: ["5898"],
      },
    }),
  });
  return parcelLayer;
};
*/

var bcparcelmap_data = function() {
  var parcelLayer = new TileLayer({
    //title: 'Parcel lines',
    displayInLayerPopup: "0",
    opacity: 0.7,
    minZoom: 12,
    visible: false,

    source: new TileWMS({
      url:
        "https://openmaps.gov.bc.ca/geo/pub/WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW/ows",
      crossOrigin: null,
      params: {
        LAYERS: "WHSE_CADASTRE.PMBC_PARCEL_FABRIC_POLY_SVW",
        //STYLES: ["5903"],
      },
    }),
  });
  return parcelLayer;
};

var bcparcelmap = function() {
  var parcelLayer = new TileLayer({
    title: 'Parcel lines',
    displayInLayerPopup: "0",
    opacity: 0.5,
    minZoom: 12,
    visible: true,
    zIndex: 5,
    preload: Infinity,
    source: new TileWMS({
      url:
        "https://geoterratone.com:/geoserver/Terradots/wms?version=1.1.0",
      crossOrigin: null,
      params: {
        //LAYERS:"BCParcel,vancouvertielines",
        //LAYERS:"metrovanfvparcel,vancouvertielines",
        LAYERS:"greatervancouverparcel,vancouvertielines",
        TILED: true
      },
    }),
  });
  return parcelLayer;

};

var vbcparcelmap=function(){
  var vparcelLayer=new VectorTileLayer({
    className: 'mylayer',
    source: new VectorTileSource({
      url: "http://geoterratone.com:8080/geoserver/gwc/service/tms/1.0.0/Terradots:BCParcel@EPSG%3A900913@pbf/{z}/{x}/{-y}.pbf",
      crossOrigin: null,
      format: new MVT(),
      wrapX: true,
      tileGrid: createXYZ({ maxZoom: 20 }),
    }),
    minResolution: 0,
    maxResolution: 3,
    renderMode: "hybrid"
  });
  return  vparcelLayer
};
var vanzoningmap = function() {
  var parcelLayer = new TileLayer({
    title: 'Vancouver Zoning',
    displayInLayerPopup: "0",
    opacity: 0.7,
    minZoom: 14,
    visible: true,
    zIndex: 4,

    source: new TileWMS({
      url:
        "http://chens.dyndns.org:8080/geoserver/Terradots/wms?version=1.1.0",
      crossOrigin: null,
      params: {
        LAYERS: "zoning-districts-and-labels",
        
      },
    }),
  });
  return parcelLayer;
};
var bookmarksmap = function() {
  var style = [
    new Style({
      stroke: new Stroke({
        color: "black",
        width: 4,
      }),
      fill: new Fill({
        color: "green",
      }),
    }),
  ];
  var bookmarkLayer = new VectorLayer({
    title: "Bookmarks",
    name: "Bookmarks",
    source: new VectorSource(),
    style: style,
    visible: false,
    zIndex: 4,
  });

  return bookmarkLayer;
};

var Torontoparcelmap = function() {
  var parcelLayer = new TileLayer({
    title: 'Greater Toronto Parcel lines',
    displayInLayerPopup: "0",
    opacity: 0.5,
    minZoom: 12,
    visible: true,
    zIndex: 5,
    preload: Infinity,
    source: new TileWMS({
      url:
         //"https://chens.dyndns.org:8443/geoserver/Terradots/wms?version=1.1.0",
        //"http://ec2-44-201-192-230.compute-1.amazonaws.com:8080/geoserver/Terradots/wms?version=1.1.0",
        "https://geoterratone.com/geoserver/terradots/wms?version=1.1.0",
      crossOrigin: null,
      params: {
        //LAYERS: "bcparcel27082022,property-tie-lines",
        //LAYERS:"GreaterVancouver,VancouverTieLines"
        LAYERS:"GreaterTorontoParcel",

        
      },
    }),
  });
  return parcelLayer;
};


var mapconfig = {
  x: -123.1175904,
  y: 49.28764,
  zoom: 17,
  //streetmap: streetmap(),
  googlemap: googlemap(),
  satellitemap: satellitemap(),
  bcparcelmap: bcparcelmap(),
  bookmarksmap: bookmarksmap(),
  indmap: indmap(),
  //mbmap: mbmap(),
  bcparceldata: bcparcelmap_data(),
  //vbcparcelmap:vbcparcelmap(),
  //vanzoningmap:vanzoningmap(),
  //torontoparcelmap:Torontoparcelmap(),
};
export default mapconfig;
